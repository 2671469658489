import React from "react"
import Banner from "./banner"
import "./terms.css"

const Terms = () => {
  return (
    <div className="terms">
      <Banner />
      <div className="terms-content">
        <h1>
          <strong>Terms</strong> of Use
        </h1>
        <div className="terms-body">
          <p>
            Reference to Compare n Save within this Website means Compare n Save
            Pty Ltd ACN 609 169 413 hereinafter referred to as The Company.
            Access and use of this Website is subject to these Terms of Use and
            The Company’s Privacy Act Statement. By using this Website you agree
            to these Terms of Use.
          </p>

          <h6>CHANGES</h6>
          <p>
            The Company reserves the right to change, modify, add or remove any
            part or parts of these Terms of Use at its sole discretion. Your use
            and continued use of this Website will be deemed asan acceptance of
            these Terms of Use and any changes that may have taken effect.
            Please checkthese Terms of Use on a regular basis for any changes.
          </p>

          <h6>INTELLECTUAL PROPERTY</h6>
          <p>
            The Company claims all intellectual property rights and copyright
            over all the materials appearingon this Website. All other business
            names and trademarks appearing on this Website are the property of
            their respective owners.
          </p>

          <h6>USE OF MATERIALS</h6>
          <p>
            The Company authorises the user to print and copy the materials
            published on this Website subject to the materials being used
            exclusively for personal use, that the materials are not modified in
            way and that The Company is acknowledged as the source. Use of the
            materials in this Website for any commercial purpose is strictly
            prohibited.
          </p>

          <h6>AUSTRALIAN RESIDENTS ONLY</h6>
          <p>
            The materials and information presented on this Website are designed
            and directed to Australian residents only.
          </p>

          <h6>ACCURACY OF INFORMATION</h6>
          <p>
            The materials presented on this Website including but not limited to
            interest rates, calculators, lender panel etc. has been prepared
            from information believed to be reliable and accurate. The
            information presented on this Website may be reliant on third party
            information and is subject to change without notice. Although best
            endeavours will be made to ensure the accuracy of the information,
            it may not always be up to date, totally accurate or complete.
          </p>

          <h6>INDEMNITY</h6>
          <p>
            The user indemnifies The Company, its officers, employees, agents,
            brokers and affiliates from and against all actions, claims, suits,
            demands, damages, liabilities, costs or expenses arising out of or
            in any way connected to the use of this Website.
          </p>

          <h6>WARRANTIES</h6>
          <p>
            Although The Company will endeavour to ensure the accuracy,
            reliability and security of this Website, the user acknowledges that
            we do not make any express or implied warranties of any kind to the
            user including but not limited to whether this Website is free from
            any errors, omissions, defects, viruses, delays or interruptions of
            service.
          </p>

          <h6>LIMITATION OF LIABILITY</h6>
          <p>
            To the maximum extent permitted by law all warranties, conditions,
            terms expressed or implied, breaches of contact, negligence and
            representations about The Company, access and use of thisWebsite,
            information provided on this Website, and products and services
            advertised, offered or supplied via this Website are excluded. Where
            our liability cannot be fully excluded it will be limited at our
            option to resupplying our service to you or paying for the resupply
            of our service to you.
          </p>

          <h6>GOVERNING LAW</h6>
          <p>
            These Terms of Use are governed by the laws of Victoria, Australia
            and the parties irrevocably submit to the exclusive jurisdiction of
            the courts in Victoria, Australia.
          </p>

          <h6>SEVERABILITY</h6>
          <p>
            Any provision (in part or whole) of these Terms of Use held to be
            unenforceable, invalid or illegal for any reason will be severed
            from these Terms of Use and will not invalidate the remaining
            provisions of these Terms of Use.
          </p>

          <h6>ABOUT US</h6>
          <p>
            The Company provides a no cost home and investment loan broking
            service. We are not a lender, credit provider or licensed investment
            advisor. Where you may require financial or investment advice we
            suggest you seek the services of a licensed advisor.
          </p>

          <h6>FEES AND CHARGES</h6>
          <p>
            The Company does not charge a fee for the majority of home and
            investment loan broking services offered via this Website although
            standard lender application fees and government charges apply. Where
            commercial properties are used as security, brokerage and other fees
            mayapply. The Company receives fees from the lenders when users
            finalise mortgage loan transactions via our service.
          </p>

          <h6>LOAN APPROVALS</h6>
          <p>
            The approval of all mortgage loan applications are subject to the
            lending criteria of the lender chosen by the user.
          </p>

          <h6>THIRD PARTIES</h6>
          <p>
            Where you have clicked-through to The Company via one of our
            affiliate websites or emails, we may pay the affiliate a marketing
            fee for the introduction.
          </p>

          <h6>HOW TO CONTACT US</h6>
          <p>
            You can request further information about The Company’s Terms of Use
            by writing to:
          </p>
          <ul>
            <li>The Manager</li>
            <li>Compare n Save Pty Ltd</li>
            <li>17 Station Street</li>
            <li>Malvern 3144</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Terms
